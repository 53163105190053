<template>
	<section class="login-content">
		<div class="container h-100">
			<div class="row align-items-center justify-content-center h-100">
				<div class="col-md-5">
					<div class="card">
						<div class="card-body">
							<div class="auth-logo">
								<h4 style="color: #4788ff; font-weight: 600;">STUDENT APP</h4>
							</div>
							<h2 class="mb-2 text-center">Login</h2>
							<p class="text-center">To Keep connected with us please login with your personal info.</p>
							<form @submit.prevent="onSubmit()">
								<div class="row">
									<div class="col-lg-12">
										<div class="form-group">
											<label>Username</label>
											<input class="form-control" type="email" placeholder="username" v-model="email" v-bind:class="{ 'is-invalid': Boolean(errors.email) }" />
											<div v-for="(val, index) in errors.email" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-lg-12">
										<div class="form-group">
											<label>Password</label>
											<input class="form-control" type="password" placeholder="********" v-model="password" v-bind:class="{ 'is-invalid': Boolean(errors.password) }" />
											<div v-for="(val, index) in errors.password" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-lg-6">
										<div class="custom-control custom-checkbox mb-3"></div>
									</div>
								</div>
								<div class="d-flex justify-content-between align-items-center">
									<span></span>
									<button type="submit" class="btn btn-primary">Login</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import axios from "axios";
	export default {
		name: "SignIn",
		data() {
			return {
				errors: {},
			};
		},
		methods: {
			onSubmit() {
				const data = { email: this.email, password: this.password };
				axios
					.post("https://api.indianeducation.co.in/api/login", data)
					.then((response) => {
						this.responseData = response.data.data;
						if (this.responseData.token) {
							localStorage.setItem("token", this.responseData.token);
							this.$router.push({ name: "dashboard" });
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error("Failed to fetch token, please try again");
						}
					})
					.catch((error) => {
						if (error.response) {
							console.log(error.response);
							this.errors = error.response.data.message;
							if (error.response.data) {
								this.$vToastify.error(error.response.data.message);
							} else {
								this.errorMessage = error.message;
								this.$vToastify.error("Error!");
							}
						} else {
							this.errorMessage = error.message;
							this.$vToastify.error("Error!");
						}
					});
			},
			// onSubmit() {
				
			// 	this.$router.push({ name: "dashboard" });
			// },
		},
	};
</script>
